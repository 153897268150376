<template>
    <div id="collection" class="grid">
        <router-link :id="'projectID-'+ project.__typename.toLowerCase() + '-' + project.id" class="item-wrapper" v-for="project in projectsContent" :key="project.slug" :to="'/projects/' + project.slug">
    
            <div class="item-details">
                <p>{{ project.title }}</p>
                <p v-if="project.meta.year">{{ project.meta.year }}</p>
            </div>

            <div v-if="project.photo_cover" class="item-image">

                <img v-if="checkView == 'two-col' && project.photo_cover.formats.large" :src="api_url + project.photo_cover.formats.large.url" :alt="'fsh-project-' + project.slug + '-large'" height="1024" width="576">

                <img v-else-if="checkView == 'four-col' && project.photo_cover.formats.medium" :src="api_url + project.photo_cover.formats.medium.url" :alt="'fsh-project-' + project.slug + '-medium'" height="640" width="360">

                <img v-else-if="checkView == 'six-col' && project.photo_cover.formats.small" :src="api_url + project.photo_cover.formats.small.url" :alt="'fsh-project-' + project.slug + '-small'" height="320" width="180">

                <img v-else-if="checkView == 'six-col' && project.photo_cover.formats.thumbnail" :src="api_url + project.photo_cover.url" :alt="'fsh-project-' + project.slug + '-thumb'" height="160" width="90">

                <div v-else class="item-image--missing">
                    <p>{{ project.title }}</p>
                </div>
                
            </div>
            <div v-else class="item-image--missing">
                <p>{{ project.title }}</p>
            </div>
    
        </router-link>
    
    </div>
</template>

<script>
export default {
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    computed: {
        checkView() {
            return this.$store.state.view
        }
    },
    props: {
        projectsContent: Array
    },
}
</script>