<template>
    <div>
        <transition name="txtpanel">
            <!-- <section id="txt-view" v-show="visibleText" :class="visibleText ? 'visible' : 'invisible'"> -->
            <section id="txt-view" v-show="visibleText" v-if="detail">
                <div id="details" class="split-col">
                    <div class="split-l">
                        <p>{{ detail.details.address }}</p>
                        <p><span>{{ detail.details.zipcode }}</span><span> {{ detail.details.city }}</span></p>
                        <p>{{ detail.details.country }}</p>
                    </div>
                    <div class="split-r">
                        <p class="link"><a :href="'tel:' + detail.details.mobile">{{ detail.details.mobile }}</a></p>
                        <p class="link"><a :href="'mailto:' + detail.details.email">Email</a></p>
                        <p class="link"><a :href="'https://' + detail.details.instagram_url" target="_blank" rel="noreferrer noopener">Instagram</a></p>
                        <p class="link"><a :href="'https://' + detail.details.facebook_url" target="_blank" rel="noreferrer noopener">Facebook</a></p>
                        <p class="link"><a :href="'https://' + detail.details.twitter_url" target="_blank" rel="noreferrer noopener">Twitter</a></p>
                        <p v-if="detail.resume_file" class="link">
                            <a :href="api_url + detail.resume_file.url" download="resume.pdf" target="_blank" rel="noreferrer noopener">Curriculum Vitae</a>
                        </p>
                    </div>
                </div>
    
                <div id="description">
                    <MarkedParser :markedContent="detail.content_nb" />
                </div>
    
                <ul id="cv" class="cv--table">
                    <li class="cv--row">
                        <div class="filters">
                            <p @click="sortTitle()" class="row-grid-two"><span>Title</span></p>
                            <p @click="sortType()" class="row-grid-one"><span>Type</span></p>
                            <p @click="sortYear()" class="row-grid-one"><span>Year</span></p>
                        </div>
                    </li>
                    <TableRow v-for="project in projects" :key="project.slug" :tableRowContent="project" />
                </ul>
    
            </section>
        </transition>
    
        <transition name="imgpanel">
            <!-- <section v-if="works" ref="imgView" id="img-view" :class="onImg ? 'visible' : 'invisible'"> -->
            <section id="img-view" v-if="projects" v-show="visibleImg" ref="imgView">
                <Projects :projectsContent="projects" :class="viewState" />
            </section>
        </transition>
    
    
    </div>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import TableRow from '@/components/containers/TableRow'

// @ is an alias to /src
import gql from 'graphql-tag'
import _ from 'lodash';
import Projects from '../components/containers/Projects'

const getDetails = gql `
      query Details {
        detail {
          id
          content_nb
          details {
              address
              zipcode
              city
              country
              mobile
              email
              instagram_url
              facebook_url
              twitter_url
          }
          resume_file {
              id
              url
          }
        }
      }
  `;

const getProjects = gql `
    query Projects {
        works {
            id
            slug
            title
            meta {
                year
            }
            photo_cover {
                formats
                url
            }
        },
        publications {
            id
            slug
            title
            meta {
                year
            }
            photo_cover {
                formats
                url
            }
        },
        exhibitions {
            id
            slug
            title
            meta {
                year
            }
            photo_cover {
                formats
                url
            }
        },
    }
`;


// import { gsap, TimelineLite, Circ } from 'gsap'
import { gsap, Circ } from 'gsap'
export default {
    name: 'Lander',
    data() {
        return {
            order: true,
            projects: [],
            foobar: [],
            onTxt: false,
            onImg: true,
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    apollo: {
        projects: {
            query: getProjects,
            result({ data }) {
                // console.log('We got some result!')
                // console.log(data.works)
                // console.log(data.publications)
                // console.log(_.union(data.works, data.publications));
                data = _.union(data.works, data.publications, data.exhibitions);
                const sortedContent = _.orderBy(data, ['meta.year'], ['desc']);
                // console.log(sortedContent)
                this.projects = sortedContent
            },
            update: data => data
        },
        detail: {
            query: getDetails
        },
    },
    computed: {
        visibleImg() {
            return this.$store.state.img
        },
        visibleText() {
            return this.$store.state.text
        },
        viewState() {
            return this.$store.state.view
        },

    },
    created() {
        this.$root.$on('update', payload => {
            console.log(payload);
            this.shuffleDeck();
        });
    },
    mounted() {
        gsap.fromTo('#img-view', { opacity: 0, y: 10 }, { ease: Circ.easeOut, opacity: 1, y: 0 });

        this.$store.commit('changeCurrent', null)

        // window.addEventListener('keyup', logKey);
        // function logKey(e) {
        //     console.log(`${e.code}`);
        // }

        window.addEventListener("keyup", event => {
            if (event.code === 'KeyR') {
                this.shuffleDeck();
            }
        });

    },
    methods: {
        shuffleDeck() {        
            for(let i = this.projects.length - 1; i > 0; i--) {
                let vm = this
                let randomIndex = Math.floor(Math.random() * i);
                
                let temp = this.projects[i];
                vm.$set(this.projects, i, this.projects[randomIndex]);
                vm.$set(this.projects, randomIndex, temp);
            }
        },
        sortTitle() {
            // console.log('Sorting on Title')
            if(this.order == false) { 
                this.projects.sort((a, b) => (a.title < b.title));
                this.order = true;
            } else if (this.order == true) {
                this.projects.sort((a, b) => (a.title > b.title));
                this.order = false;
            }
        },
        sortType() {
            // console.log('Sorting on Type')
            if(this.order == false) { 
                this.projects.sort((a, b) => (a.__typename < b.__typename));
                this.order = true;
            } else if (this.order == true) {
                this.projects.sort((a, b) => (a.__typename > b.__typename));
                this.order = false;
            }
        },
        sortYear() {
            // console.log('Sorting on Year')
            if(this.order == false) { 
                this.projects.sort((a, b) => (a.meta.year < b.meta.year));
                this.order = true;
            } else if (this.order == true) {
                this.projects.sort((a, b) => (a.meta.year > b.meta.year));
                this.order = false;
            }
        }
    },
    components: {
        MarkedParser,
        TableRow,
        Projects
    },


}
</script>
