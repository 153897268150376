<template>
    <li class="cv--row">
        <router-link :to="'projects/' + tableRowContent.slug">
            <p class="row-grid-two">{{ tableRowContent.title }}</p>
            <p class="row-grid-one">{{ tableRowContent.__typename }}</p>
            <p v-if="tableRowContent.meta.year" class="row-grid-one">{{ tableRowContent.meta.year }}</p>
        </router-link>
    </li>
</template>

<script>
    export default {
        props: {
            tableRowContent: Object
        }        
    }
</script>