<template>
    <div class="error">
        <p>
            <span class="error-code">
                Error 404
            </span>
            <span class="content">
                We are sorry, this page does not exist.
            </span>
        </p>
    </div>
</template>

<script>

export default {
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    }
}
</script>

<style lang="scss" scoped>
.error {
    display: flex;
    height: 100%;
    position: relative;
    .error-code {
        color: red;
        margin-right: 2rem;
    }
}
</style>